/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Fullmap, FullmapWrap, Subtitle, Title, Image, SeparateLine, SeparateLineWrap, Text, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Nový Jičín"}>
        <Column className="js-anim  --anim2 --anim-s3 --left" anim={"2"} name={"uvod-2"} animS={"3"} style={{"backgroundColor":"rgba(241,236,205,1)"}}>
        </Column>


        <Column className="--left">
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left">
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 mb--0 mt--0 pb--0 pl--20 pr--20 pt--0 flex--center" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"gvagkt918ph"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column name={"4zyfevec71k"}>
        </Column>


        <Fullmap className="--style2" name={"4tbscrqd7gw"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--10 pt--30" name={"zrw85eney4f"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--24" content={"<span style=\"color: rgb(241, 236, 205);\">Kontakt</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"color: rgb(241, 236, 205);\">Tel.:&nbsp; +420 703 979 705&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--24" content={"<span style=\"color: rgb(241, 236, 205);\">Otevírací doba</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"color: rgb(241, 236, 205);\">PO - PÁ _ 9°° - </span>&nbsp;<span style=\"color: rgb(241, 236, 205);\">18°°<br>SO _ 10</span>°° - 20°°<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box fs--24" content={"<span style=\"color: rgb(241, 236, 205);\">Adresa</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20" content={"Nový Jičín, Žerotínova 14 čp. 63<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--12 pt--40" name={"csw5apquixc"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":1090,"backgroundColor":"rgba(241,236,205,1)"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/dcdfb070741b44b3a5e76c17b7540c49_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19803/dcdfb070741b44b3a5e76c17b7540c49_s=350x_.png 350w, https://cdn.swbpg.com/t/19803/dcdfb070741b44b3a5e76c17b7540c49_s=660x_.png 660w, https://cdn.swbpg.com/t/19803/dcdfb070741b44b3a5e76c17b7540c49_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/ea76e428f2424d71a99a5a0dd1d935a9_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19803/ea76e428f2424d71a99a5a0dd1d935a9_s=350x_.png 350w, https://cdn.swbpg.com/t/19803/ea76e428f2424d71a99a5a0dd1d935a9_s=660x_.png 660w, https://cdn.swbpg.com/t/19803/ea76e428f2424d71a99a5a0dd1d935a9_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19803/5712152c87c344e783ac294825b6529b_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/19803/5712152c87c344e783ac294825b6529b_s=350x_.png 350w, https://cdn.swbpg.com/t/19803/5712152c87c344e783ac294825b6529b_s=660x_.png 660w, https://cdn.swbpg.com/t/19803/5712152c87c344e783ac294825b6529b_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"0lsantykcxh"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--06 pt--06" name={"0h5kua54sia8"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--26 pb--30 pt--16" style={{"maxWidth":650}} content={"💈&nbsp;<span style=\"color: rgb(241, 236, 205);\">Nový Barbershop v Novém Jičíně&nbsp;</span>💈<span style=\"color: rgb(241, 236, 205);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"7z12pl247lk"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"pg9diquxq3d"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30 pb--12" content={"<span style=\"background-color: rgb(241, 236, 205); color: var(--black);\">&nbsp; &nbsp; Ceník služeb&nbsp; &nbsp;&nbsp;&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"60s3b0bofqr"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--22" content={"<span style=\"color: rgb(241, 236, 205);\">Klasický střih s mytím&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"<span style=\"color: rgb(241, 236, 205);\">Stříhání vlasů, mytí vlasů, zaholení kontur, úprava obočí, vysušení, styling a na závěr kolínská.\n(30 min.)</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(241, 236, 205);\">350,— Kč</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--pbtn2" url={"https://la-kapo-barbershop.reservio.com/"} href={"https://la-kapo-barbershop.reservio.com/"} style={{"backgroundColor":"rgba(19,98,109,1)"}} content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--22" content={"<span style=\"color: rgb(241, 236, 205);\">Klasický střih s mytím + Úprava vousů \"Hot towel\"</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"<span style=\"color: rgb(241, 236, 205);\">Stříhání vlasů, mytí vlasů, styling, úprava vousů, úprava obočí, napaření vousů metodou HOT TOWEL, zaholení kontur, nebo holení do hladka. Na závěr balzám a kolínská.\n(60 min.)</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(241, 236, 205);\">550,— Kč</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--pbtn2" url={"https://la-kapo-barbershop.reservio.com/"} href={"https://la-kapo-barbershop.reservio.com/"} style={{"backgroundColor":"rgba(19,98,109,1)"}} content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--22" content={"<span style=\"color: rgb(241, 236, 205);\">Úprava vousů \"Hot towel\"</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"<span style=\"color: rgb(241, 236, 205);\">Úprava vousů, napaření vousů metodou HOT TOWEL, zaholení kontur, nebo holení \ndo hladka. Na závěr balzám a kolínská.\n(30 min.)</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(241, 236, 205);\">350,— Kč</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--pbtn2" url={"https://la-kapo-barbershop.reservio.com/"} href={"https://la-kapo-barbershop.reservio.com/"} style={{"backgroundColor":"rgba(19,98,109,1)"}} content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box fs--22" content={"<span style=\"color: rgb(241, 236, 205);\">Proměna - střih z dlouhých vlasů na krátké</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"<span style=\"color: rgb(241, 236, 205);\">Stříhání z dlouhých vlasů na krátké, mytí vlasů, zaholení kontur, úprava obočí, vysušení, styling a na závěr balzám a kolínská.\n(60 min.)</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(241, 236, 205);\">550,— Kč</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--pbtn2" url={"https://la-kapo-barbershop.reservio.com/"} href={"https://la-kapo-barbershop.reservio.com/"} style={{"backgroundColor":"rgba(19,98,109,1)"}} content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box fs--22" content={"<span style=\"color: rgb(241, 236, 205);\">Proměna - střih z dlouhých vlasů na krátké + Úprava vousů&nbsp;</span>\"Hot towel\"<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"<span style=\"color: rgb(241, 236, 205);\">Stříhání dlouhých vlasů na krátké, mytí vlasů, styling, úprava vousů, napaření vousů metodou HOT TOWEL, úprava obočí, zaholení kontur, nebo holení do hladka. Na závěr balzám a kolínská.\n(90 min.)</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(241, 236, 205);\">800,— Kč</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--pbtn2" url={"https://la-kapo-barbershop.reservio.com/"} href={"https://la-kapo-barbershop.reservio.com/"} style={{"backgroundColor":"rgba(19,98,109,1)"}} content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50">
              
              <Title className="title-box fs--22" content={"<span style=\"color: rgb(241, 236, 205);\">All inclusive</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":335}} content={"<span style=\"color: rgb(241, 236, 205);\">Stříhání vlasů, mytí vlasů, styling, úprava vousů, úprava obočí, napaření vousů metodou HOT TOWEL, zaholení kontur, nebo holení do hladka či barvení vousů. Služba nadále obsahuje 15minutovou masáž hlavy a šíje, černou masku na obličej, opalování ušních chloupků. Na závěr aplikujeme prvotřídní kolínskou.\n(90 min.)</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(241, 236, 205);\">900,— Kč</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--pbtn2" url={"https://la-kapo-barbershop.reservio.com/"} href={"https://la-kapo-barbershop.reservio.com/"} style={{"backgroundColor":"rgba(19,98,109,1)"}} content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"npc71atjwol"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--22" content={"<span style=\"color: rgb(241, 236, 205);\">Dětský střih do 12 let</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(241, 236, 205);\">Stříhání vlasů, zaholení kontur, styling a na závěr kolínská. Prosíme o doklad s fotografií.</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"250,— Kč"}>
              </Subtitle>

              <Button className="btn-box btn-box--pbtn2" url={"https://la-kapo-barbershop.reservio.com/"} href={"https://la-kapo-barbershop.reservio.com/"} style={{"backgroundColor":"rgba(19,98,109,1)"}} content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--22" content={"<span style=\"color: rgb(241, 236, 205);\">Holení hlavy do hladka + Úprava vousů \"Hot towel\"</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(241, 236, 205);\">Holení hlavy do hladka, úprava vousů, úprava obočí, napaření vousů metodou HOT TOWEL, zaholení kontur, nebo holení do hladka. Na závěr a kolínská. (30 min.)</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"400,— Kč"}>
              </Subtitle>

              <Button className="btn-box btn-box--pbtn2" url={"https://la-kapo-barbershop.reservio.com/"} href={"https://la-kapo-barbershop.reservio.com/"} style={{"backgroundColor":"rgba(19,98,109,1)"}} content={"Rezervace"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"oslqhv0re3b"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(255, 0, 0);\">Pokud se nemůžete dostavit na objednaný termín, zrušte jej prosím nejpozději 24 h předem. \nPokud tak neučiníte, bude Vám při další návštěvě účtován poplatek 50 % z ceny propadlé služby. \n</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"yqz0zpcsqra"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--left pb--60 pt--60" name={"akuz5dwtgf"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"<span style=\"color: rgb(241, 236, 205);\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(241, 236, 205);\">Tel.:&nbsp; +420 709 979 705<br>Email: lakapobarber@seznam.cz\n<br>Instagram: @la_kapo_barber<br>Facebook: La Kapo Barbershop<br>Adresa:&nbsp;Nový Jičín, Žerotínova 14 čp. 63<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--14" style={{"maxWidth":650}} content={"<span style=\"color: rgb(241, 236, 205);\"><br>Barbershop si vyhrazuje právo na změny ceníku a specifikaci služeb.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}